.container {
  max-width: 375px;
  margin: 0 auto;
  padding: 0 20px 50px 20px;
  @media (min-width: 768px) {
    max-width: 1200px;
  }
}

.opis {
  font-size: 16px;
  font-weight: 700;
  color: beige;
}
.bg {
  position: relative;
  margin-top: 0;
}

.bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/beczki/czarna.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
  z-index: -1;
}
.boxflex {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}

.dlaczego {
  text-align: center;
  // margin-top: 60px;
  // pa-top: 50px;
  margin-top: 0;
  padding-top: 60px;
  // margin-bottom: 60px;
  font-size: 30px;
  text-transform: uppercase;
}
.desc {
  text-align: left;
  margin: 0 auto 100px auto;
  width: 90%;
  font-size: 14px;
  font-weight: 400;
  color: beige;

  letter-spacing: normal;
  line-height: 1.5;
}

.img {
  width: 80px;
  height: 80px;
}
.center {
  text-align: center;
  margin-right: 30px;
  border: 2px solid white;
  border-radius: 20px;
  padding: 20px;
  transition: 0.3s ease-in-out;
}
.center:hover {
  transform: scale(1.1);
  transform-origin: center;
  background-color: #9b6b6b;
}

.heroImage {
  border: 2px solid white;
  display: block;
  margin: 0 auto;
  width: 90%;
  height: auto;
}
.center {
  margin-bottom: 40px;
}
.odtl {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  height: auto;
  border-radius: 10px;
}

@media (min-width: 768px) {
  .container {
    margin-top: 100px;
  }
  .boxflex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .dlaczego {
    text-align: center;
    margin-bottom: 60px;
    font-size: 30px;
  }
  .desc {
    text-align: left;
    margin: 0 auto 100px auto;
    width: 90%;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 1.5;
  }

  .img {
    width: 100px;
    height: 100px;
  }
  .center {
    text-align: center;
    // margin-right: 30px;
    margin: 30px auto 0 auto;
    border: 2px solid white;
    border-radius: 20px;
    padding: 20px;
    transition: 0.3s ease-in-out;
    width: 250px;
  }
  .center:hover {
    transform: scale(1.1);
    transform-origin: center;
    background-color: #9b6b6b;
  }

  .heroImage {
    border: 2px solid white;
    display: block;
    margin: 0 auto;
    width: 90%;
    height: auto;
  }
  .odtl {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  // .bg {
  //   width: 100%;
  //   background-image: url(../../assets/alpfoto/realiza/panorama.jpg);
  //   background-position: center;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   opacity: 0.2;
  // }
  .bg {
    position: relative;
  }

  .bg::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/beczki/6.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.4;
    z-index: -1;
  }
  .opis {
    font-size: 18px;
    font-weight: 700;
    color: beige;
  }
}
