.bg {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.firstlane {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.logo {
  width: 160px;
  height: 60px;
  margin: 20px;
  border-radius: 10px;
}

.close {
  width: 20px;
  height: 20px;
  margin-top: 40px;
  margin-right: 20px;
  cursor: pointer;
}

.features,
.pricing,
.contact {
  display: block;
  text-decoration: none;
  padding: 15px;
  margin: 0 auto;
  width: 80%;
  font-size: 20px;
  text-align: center;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 1.75px;
  font-family: "Rubik", sans-serif;
  border-bottom: 1px solid grey;
}

.features {
  border-top: 1px solid grey;
}

.login {
  letter-spacing: 1.75px;
  font-family: "Rubik", sans-serif;
  display: block;
  margin: 30px auto 0 auto;
  width: 60%;
  height: 40px;
  background-color: hsla(229, 31%, 21%, 0.9);
  outline: none;
  border: 2px solid white;
  border-radius: 10px;
  color: white;
  font-size: 18px;
  text-transform: uppercase;
}

.positionsocial {
  display: flex;
  justify-content: center;
}

.icon {
  margin: 30px 20px 0 20px;
}

.dropdown {
  position: relative;
  display: block;
  border-bottom: 1px solid grey;
  width: 85%;
  margin: 0 auto;
  .dropdownBtn {
    width: 100%;
    padding: 15px;
    text-decoration: none;
    color: white;
    background-color: transparent;
    border: none;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    font-family: "Rubik", sans-serif;
    font-size: 20px;
    letter-spacing: 1.75px;

    &:hover {
      background-color: hsla(229, 31%, 21%, 0.7);
    }
  }

  .dropdownContent {
    display: none;
    flex-direction: column;
    background-color: black;
    width: 80%;
    margin: 0 auto;

    a {
      color: white;
      padding: 12px;
      text-decoration: none;
      text-align: center;
      border-bottom: 1px solid grey;

      &:hover {
        background-color: hsla(229, 31%, 21%, 0.7);
      }
    }
  }

  &.active .dropdownContent {
    display: flex;
  }
}

@media (min-width: 768px) {
  .bg {
    display: none;
  }
}
