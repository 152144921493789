.nav {
  font-size: 40px;
}

.navdesktop {
  display: none;
}
.display {
  margin-top: 30px;
  width: 100%;
  height: 320px;
  background-image: url(../../assets/beczki/biala.jpg);

  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
}
.features {
  text-align: center;
  padding: 10px;
  width: auto;
  height: 20px;
}
.btn {
  background: black;
  border: none;
  margin: 0 20px 120px 0;
  cursor: not-allowed;
  width: 40px;
  height: 40px;
}
.btnham {
  padding: 8px;
  background: black;
  border: none;

  margin: 30px 20px 0 0;
  cursor: pointer;
  width: 35px;
  height: 35px;
}
.logo {
  width: 130px;
  margin-top: 20px;
  margin-left: 30px;
  height: 60px;
  border-radius: 20px;
}

@media (min-width: 768px) {
  .logo {
    margin-top: 50px;
    width: 300px;
    height: 100px;
    border-radius: 20px;
  }
  .nav {
    display: none;
  }
  .display {
    display: none;
  }
  .boxnav {
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
    height: 65px;
    padding-top: 15px;
    border-radius: 20px;
    background-color: gray;
    opacity: 0.7;
  }
  .bgim {
    background-image: url(../../assets/beczki/biala.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .navdesktop {
    width: 100%;
    max-width: 1280px;
    height: 100vh;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .features {
    text-decoration: none;
    text-align: center;
    padding: 12px;
    margin-left: 10px;
    width: auto;
    height: 20px;
    font-size: 20px;
    color: black;
    font-weight: 700;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    font-family: "Rubik", sans-serif;
    transition: transform 0.8s ease-in-out;
    cursor: pointer;
  }
  .features:hover {
    transform: scale(1.2);
    transform-origin: center;
  }
  .pricing {
    text-decoration: none;
    text-align: center;
    padding: 12px;
    width: auto;
    height: 20px;
    font-size: 20px;
    color: black;
    font-weight: 700;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    font-family: "Rubik", sans-serif;
    transition: transform 0.8s ease-in-out;
    cursor: pointer;
  }
  .zbiorniki {
    text-decoration: none;
    text-align: center;
    padding: 12px;
    width: auto;
    height: 20px;
    font-size: 20px;
    color: black;
    font-weight: 700;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    font-family: "Rubik", sans-serif;
    transition: transform 0.8s ease-in-out;
    cursor: pointer;
  }
  .pricing:hover {
    transform: scale(1.2);
    transform-origin: center;
  }
  .oferta {
    text-decoration: none;
    font-size: 20px;
    text-align: center;
    padding: 12px;
    width: auto;
    height: 20px;
    color: black;
    font-weight: 700;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    font-family: "Rubik", sans-serif;
    transition: transform 0.8s ease-in-out;
    cursor: pointer;
  }
  .oferta:hover {
    transform: scale(1.2);
    transform-origin: center;
  }

  .contact {
    text-decoration: none;
    text-align: center;
    padding: 10px;
    width: auto;
    height: 20px;
    font-size: 20px;
    color: black;
    font-weight: 700;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    font-family: "Rubik", sans-serif;
    transition: transform 0.8s ease-in-out;
    cursor: pointer;
  }
  .contact:hover {
    transform: scale(1.2);
    transform-origin: center;
  }
  .btn {
    cursor: pointer;
    margin-left: 20px;
    margin-right: 30px;
    width: 150px;
    outline: none;
    height: 50px;
    border-radius: 8px;
    color: white;
    background-color: hsl(235, 63%, 46%);
    text-transform: uppercase;
    font-family: "Rubik", sans-serif;
  }
  .dropdown {
    position: relative;
    display: inline-block;
    margin-top: 12px;

    &:hover .dropdownContent {
      display: block;
      max-height: 500px;
      border-radius: 8px;
    }
  }

  .dropdownContent {
    border-radius: 8px;

    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
  }

  .dropdownContent a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;

    &:hover {
      background-color: yellowgreen;
    }
  }
}
