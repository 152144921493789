.container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;

  //   background-color: #121212; /* ciemne tło */
}

.title {
  font-size: 26px;
  margin-bottom: 1rem;
  color: #f0f0f0; /* jasny kolor tekstu */
  font-style: italic;
  text-align: center;
}

.description {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #e0e0e0; /* jasny kolor tekstu */
}

.subtitle {
  font-size: 20px;

  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #f0f0f0; /* jasny kolor tekstu */
  font-style: italic;
  text-align: center;
}

.subsubtitle {
  font-size: 20px;

  margin-top: 1.5rem;
  margin-bottom: 1rem;
  color: #f0f0f0; /* jasny kolor tekstu */
  font-style: italic;
  text-align: center;
}

.text {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #e0e0e0; /* jasny kolor tekstu */
}

.list {
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 1rem;

  li {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #e0e0e0; /* jasny kolor tekstu */
  }
}

.contact {
  font-size: 1rem;
  margin-top: 1.5rem;
  color: #e0e0e0; /* jasny kolor tekstu */
  font-weight: bold;
}

@media (min-width: 768px) {
  .title {
    font-size: 36px;
  }
  .subsubtitle {
    font-size: 30px;
  }
  .subtitle {
    font-size: 30px;
  }
}
