.title {
  text-align: center;
  text-decoration: none;
  color: beige;
  font-weight: 700;
  font-style: italic;
}
.description {
  font-size: 18px;
}
.photo {
  width: 300px;
  height: 220px;
  margin: 15px;
  transition: 0.3s ease-in-out;
  border-radius: 15px;
}
.photo:hover {
  transform: scale(1.1);
}
.positionphoto {
  text-align: center;
  margin: 0 auto;
}

.gallery {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}
.descphoto {
  margin-top: 0;
}
.price {
  margin-top: 0;
  margin-bottom: 50px;
}

.opis {
  font-size: 18px;
  // text-transform: uppercase;
  line-height: 1.2;
  width: 80%;
  margin: 0 auto;
  font-weight: 600;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
.boxik {
  width: 80%;
  height: auto;
  border: 2px solid white;
  border-radius: 20px;
  margin: 20px;
  padding: 10px;
  text-decoration: none;
  color: white;
  transition: 0.3s ease-in-out;
  // background-color: aqua;
}
.boxik:hover {
  transform: scale(1.1);
}
.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

@media (min-width: 768px) {
  .opis {
    font-size: 26px;
  }
  .photo {
    width: 400px;
    height: 350px;
    margin: 15px;
    transition: 0.3s ease-in-out;
    border-radius: 15px;
  }
  .gallery {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  }
  .descphoto {
    margin-top: 0;
    font-size: 18px;
  }
  .price {
    margin-top: 0;
    margin-bottom: 50px;
  }
  .title {
    font-size: 30px;
    text-align: center;
    text-decoration: none;
    color: beige;
    font-weight: 700;
    font-style: italic;
  }
  .description {
    font-size: 18px;
    margin-bottom: 80px;
  }
  .boxik {
    width: 27%;
    height: auto;
    border: 2px solid white;
    border-radius: 20px;
    margin: 20px;
    padding: 10px;
    text-decoration: none;
    color: white;
    transition: 0.3s ease-in-out;
    // background-color: aqua;
  }
  .boxik:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 40px rgba(255, 215, 0, 0.5);
  }
  .flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
