.bgmodal {
  // background-image: url(../../assets/beczki/beka2.jpg);
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  width: 300px;
  height: 500px;
  position: fixed;
  top: 140px;
  left: 50px;
  // opacity: 0;
  animation: fadeIn 1s forwards;
  border-radius: 15px;
  background-color: black;
  opacity: 0.3;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.close {
  color: white;
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: end;
  margin-right: 30px;
  cursor: pointer;
}

.title {
  color: beige;
  font-size: 20px;
  margin-bottom: 5px;
  margin-top: 10px;
}

.flex {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.input::placeholder {
  width: 200px;
  height: 100px;
  padding: 10px;
  color: black;
}

.input {
  width: 100%;
  height: 20px;
  border: 2px solid black;
  border-radius: 8px;
  opacity: 0.7;
  padding: 10px;
  color: black;
}

.input2 {
  width: 100%;
  height: 100px;
  border: 2px solid black;
  border-radius: 8px;
  opacity: 0.7;
  padding: 10px;
  color: black;
}

.input2::placeholder {
  width: 200px;
  height: 100px;
  padding: 10px;
  color: black;
}

.btn {
  margin: 30px auto 0 auto;
  width: 80%;
  background-color: yellow;
  font-size: 16px;
  border-radius: 8px;
  padding: 8px;
  color: black;
  cursor: pointer;
}

@media (min-width: 768px) {
  .bgmodal {
    width: 500px;
    height: 600px;
    position: fixed;
    top: 50px;
    left: 30%;
    z-index: 1;
  }
}
